import { animateHeight } from '../helper'
import { isDesktop, isMobile, isTablet } from '@/app/composables/useBreakpoints'

export const tabs = {
  props: {
    activeState: {
      type: Boolean,
      default: false
    },
    initOn: {
      type: String
    }
  },
  setup() {
    return {
      isMobile,
      isTablet,
      isDesktop
    }
  },
  data() {
    return {
      active: this.activeState,
      defaultHeight: 'auto',
      currentHeight: 'auto'
    }
  },
  methods: {
    changeState() {
      this.active = !this.active
      animateHeight(this.active ? 1 : 0, this.$el)
    }
  },
  computed: {
    init() {
      switch (this.initOn) {
        case 'mobile':
          return isMobile
        case 'tablet':
          return isTablet
        case 'desktop':
          return isDesktop
        default:
          return false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      animateHeight(this.active ? 1 : -1, this.$el)
    })
  }
}
